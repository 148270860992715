import { GridCellProps, GridRowClickEvent } from '@progress/kendo-react-grid'
import { showAppCue } from 'components/app-cues/actions'
import { APP_CUE_TYPE } from 'components/app-cues/constants'
import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { DropdownComponent, DropdownOption, MultiSelectDropdown, OnChangeEvent } from 'components/dropdown/component'
import * as Flash from 'components/flash'
import { GeneratedSummaryModalComponent } from 'components/generate-text'
import { HeaderComponent } from 'components/header/component'
import { SearchIcon } from 'components/icons/searchicon'
import { Loading } from 'components/loading'
import { MenuItem, TabMenu } from 'components/tab-menu/component'
import { RasaContext } from 'context'
import { differenceInSeconds } from 'date-fns'
import { Dataset } from 'generic/dataset'
import { isFalsey, isTruthy, tryParseJSON } from 'generic/utility'
import { isEmpty, keyBy } from 'lodash'
import React, {Component} from 'react'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'
import { Roles } from 'shared/constants'
import { isInGenerationWindow } from 'shared/data-layer/issues'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { ModalSize } from 'shared/modals/constants'
import { DEFAULT_COMMUNITY_PARTNER_CODES, NORMAL_BOOSTED_BOOST_LEVEL, SourceTypes, SUPER_BOOSTED_BOOST_LEVEL } from 'shared_server_client/constants'
import { BillingPlanDetailCode } from 'shared_server_client/types/billing_plan'
import { UpcomingIssue } from 'shared_server_client/types/schedule'
import { EditableDropdownCell, EditCellProps} from '../../generic/editCell'
import * as GenericRedux from '../../generic/genericRedux'
import {ChangeEvent, PagingSource, RasaDataGrid} from '../../generic/rasaDataGrid'
import * as Columns from '../grid/columns'
import { NextNewsletterSendComponent } from '../schedule-editor/nextNewsletter'
import { AddEditArticleModal } from './addEditArticleModal'
import * as Constants from './constants'
import {
  ActiveCell,
  ArticleLink,
  BoostCell,
  DateTimeCell,
  ImageCell,
  SourceCell,
  TitleCell,
  TooltipCellHeader,
} from './kendocells'
import { UpcomingArticlesModal } from './modals'
import './styles.css'
import { Article, ArticleCount, ArticleSections, DisplayArticle, EmptyDisplayArticle } from './types'
import { imageIsTooSmall } from './utility/utils'
import { ImageGalleryModal } from '../common/image-gallery/modal'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
type UpcomingArticleProps = GenericRedux.AllComponentPropsWithModal<DisplayArticle>

interface SelectedSourceHash {
  [name: string]: boolean,
}

interface ArticleHash {
  [id: number]: DisplayArticle
}

interface UpcomingArticlesState extends Columns.SizableColumnState {
  activeArticlesCount: number,
  activeToggleCount: number,
  articles: DisplayArticle[],
  articlesCounts: ArticleCount[],
  articleHash: ArticleHash,
  articlesToDisplay: DisplayArticle[],
  bulkAIImageGenerationCount: number,
  bulkAIImageGenerationOffset: number,
  bulkAIDescriptionGenerationCount: number,
  bulkAIDescriptionGenerationOffset: number,
  columns: Columns.SizableColumns,
  currentRole: string,
  communityId: string,
  emailLayoutId: number,
  emailTemplate: string,
  fixedWidth: number,
  forceReload: boolean,
  hasScheduledContentAccess: boolean,
  hasReserveContentAccess: boolean,
  hasSectionAccess: boolean,
  initialNextIssue: UpcomingIssue,
  isLoading: boolean,
  isScheduleActive: boolean,
  isSuperUser: boolean,
  mergedSources: any[],
  minimumArticlesCount: number,
  search: string,
  sections: any[],
  segments: any[],
  selected?: SelectedSourceHash,
  selectedFilterOption: DropdownOption,
  selectedSection: string,
  selectedSections: string[],
  selectedSegments: string[],
  selectedSources: any[],
  sources: any[],
  updatedArticles: any[],
  showAIIMageGenerationModal?: boolean,
  showAIDescriptionGenerationModal?: boolean,
  showBulkArticleUpdateModal?: boolean,
  isUpcomingArticlesEnabled: boolean,
  nextIssue: any,
}

class UpcomingArticlesComponent extends Component<UpcomingArticleProps, UpcomingArticlesState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  constructor(props: UpcomingArticleProps) {
    super(props);
    this.state = {
      activeArticlesCount: null,
      activeToggleCount: 0,
      articles: [],
      articleHash: {},
      articlesCounts: [],
      bulkAIImageGenerationCount: 0,
      bulkAIImageGenerationOffset: 0,
      bulkAIDescriptionGenerationCount: 0,
      bulkAIDescriptionGenerationOffset: 0,
      columns: null,
      communityId: '',
      emailLayoutId: null,
      fixedWidth: 0,
      forceReload: false,
      hasScheduledContentAccess: false,
      hasReserveContentAccess: false,
      hasSectionAccess: false,
      initialNextIssue: null,
      isLoading: false,
      isSuperUser: false,
      mergedSources: [],
      minimumArticlesCount: null,
      panelWidth: 0,
      search: '',
      sections: [],
      segments: [],
      selected: {
        All: true,
        [SourceTypes.twitter]: false,
        [SourceTypes.facebook]: false,
        [SourceTypes.rss]: false,
        [SourceTypes.scraper]: false,
        [SourceTypes.native]: false,
        [SourceTypes.youtube]: false,
      },
      selectedFilterOption: Constants.AllFilters[0],
      selectedSection: ArticleSections.active,
      emailTemplate: '',
      articlesToDisplay: [],
      currentRole: '',
      sources: [],
      selectedSections: [],
      selectedSegments: [],
      selectedSources: [],
      updatedArticles: [],
      showAIIMageGenerationModal: false,
      showAIDescriptionGenerationModal: false,
      showBulkArticleUpdateModal: false,
      isScheduleActive: false,
      isUpcomingArticlesEnabled: true,
      nextIssue: null,
    }
    this.isActiveScheduledArticle = this.isActiveScheduledArticle.bind(this)
  }

  public componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    this.sharedStore = SharedStore.instance(this.context)
    this.initialize()
  }

  public render() {
    const nextIssue = this.getNextIssue()
    // const isUpcomingPageDisabled: boolean = isInGenerationWindow(this.nextIssue, this.state.currentTime)
    return (
      <div className={`upcoming-articles ${this.state.isUpcomingArticlesEnabled ? '' : 'upcoming-article-disabled'}`}>
        <UpcomingArticlesModal
          data={this.props.modals}
          closeModal={this.props.closeModal}
          title="WARNING"/>
        <AddEditArticleModal
          data={this.props.modals}
          allowSegment={true}
          defaultSegmentCode={DEFAULT_COMMUNITY_PARTNER_CODES.ALL}
          allowGeneratingViaChatGPT={true}
          isScheduled={false}
          updateModal={this.props.updateModal}
          openModal={this.props.openModal}
          saveModal={this.props.saveModal}
          closeModal={this.props.closeModal}
          afterSave={this.postEditArticle}
          title="Edit Article"
          size={ModalSize.Large} />
        <div className="header-container">
          <HeaderComponent
            title={'ARTICLES'}
            subTitle={'Upcoming'}
            description={['Articles eligible for your next newsletter. This is everything since your last send.']}
          />
          <div className="add-new-article-and-next-newsletter-wrapper">
            <Button className="add-button add-new-article " onClick={() =>
              this.props.openModal(AddEditArticleModal.key, {
                communityId: this.state.communityId,
              })}>
              <span className="button-text">Add Article</span>
            </Button>
            {!isEmpty(nextIssue) &&
            <NextNewsletterSendComponent nextIssue={nextIssue}/>
            }
          </div>
        </div>
        {this.state.isLoading
        ? <div className="main-loading"><Loading size="64"/></div>
        : <div>
            {!this.state.isScheduleActive &&
             <div className="upcoming-schedule-msg" onClick={() => this.handleClick(DashboardMenuOption.schedule)}>
              {'Your schedule is paused. Unpause your schedule to start pulling content in.'}
             </div>
            }
            <div className="search-bar">
              <label>
                <SearchIcon/>
                <input type="text"
                  placeholder="Search by title, description, source_name or url"
                  onChange={(e) => this.setSearch(e.target.value)}/>
              </label>
            </div>
            <div className="filter-section">
              <div className="filter-icon-container">
                <i className="fa fa-filter" aria-hidden="true" />
              </div>
              <DropdownComponent data={Constants.AllFilters.filter((item) =>
                // Need to check for the length of section > 1 because we always have a No section option
                (this.state.sections.length > 1 || item.key !== Constants.FilterOption.SECTION) &&
                (this.state.sources.length > 0 || item.key !== Constants.FilterOption.SOURCE) &&
                // Need to check for the length of segments > 2 because we always have All Segments & No Code options
                (this.state.segments.length > 2 || item.key !== Constants.FilterOption.SEGMENT) &&
                (this.state.selectedSection === ArticleSections.inactive || item.key !== Constants.FilterOption.AI_RECOMMEND_DEACTIVATION)
              )}
              selected={this.state.selectedFilterOption.key}
              onChange={ (e: OnChangeEvent) =>
                this.setState({
                  selectedFilterOption: e.selected,
                  selectedSections: [],
                  selectedSources: [],
                })
              }/>
              {this.state.selectedFilterOption.key === Constants.FilterOption.NO_IMAGE && this.getDisplayedArticles().length > 0 &&
              <div className="bulk-generation-wrapper">
                <Button className="active-inactive-button bulk-generation-button"
                  onClick={() => this.showBulkAIImageGenerationModal()}>
                  <span className="button-text">AI Generate Images</span>
                </Button>
                {this.getBulkAIImageGenerationModal()}
              </div>}
              {this.state.selectedFilterOption.key === Constants.FilterOption.NO_DESCRIPTION && this.getDisplayedArticles().length > 0 &&
              <div className="bulk-generation-wrapper">
                <Button className="active-inactive-button bulk-generation-button"
                  onClick={() => this.showBulkAIDescriptionGenerationModal()}>
                  <span className="button-text">AI Generate Description</span>
                </Button>
                {this.getBulkAIDescriptionGenerationModal()}
              </div>}
              {this.state.selectedFilterOption.key === Constants.FilterOption.SOURCE && this.state.sources.length > 0 &&
                <MultiSelectDropdown
                  placeholder="All Sources"
                  className={'multi-select-dropdown'}
                  defaultValue={this.state.mergedSources ? this.state.mergedSources[0].id : null}
                  options={this.state.mergedSources.map((source) => {
                    return {
                      label: source.name,
                      value: source.id,
                    }
                  })}
                  onSelect={(e) => this.onSourcesFilterChange(e)}
                />}
                {this.state.selectedFilterOption.key === Constants.FilterOption.SEGMENT && this.state.segments.length > 2 &&
                <MultiSelectDropdown
                  placeholder="All Segments"
                  className={'multi-select-dropdown'}
                  defaultValue={this.state.segments ? this.state.segments[0].code : null}
                  options={this.state.segments.map((segment) => {
                    return {
                      label: segment.code,
                      value: segment.code,
                    }
                  })}
                  onSelect={(e) => this.onSegmentsFilterChange(e)}
                />}
                {this.state.selectedFilterOption.key === Constants.FilterOption.SECTION
                  && this.state.sections.length > 1 &&
                  <MultiSelectDropdown
                    placeholder="All Sections"
                    className={'multi-select-dropdown'}
                    defaultValue={this.state.sections ? this.state.sections[0].code : null}
                    options={this.state.sections.map((section) => {
                      return {
                        label: section.name,
                        value: section.name,
                      }
                    })}
                   onSelect={(e) => this.onSectionsFilterChange(e)}
                  />}
            </div>
            <div className="bulk-status-update-wrapper">
              <Button disabled={this.getbulkUpdateArticlesCount() === 0} className="active-inactive-button bulk-status-update-button"
                onClick={() => this.bulkArticleUpdate()}>
                <span className="button-text">{
                  this.state.selectedSection === ArticleSections.active ? 'Deactivate ' : 'Activate '}
                  {this.getbulkUpdateArticlesCount()} articles
                </span>
              </Button>
              {this.getBulkArticleUpdateModal()}
            </div>
            <div>
              <TabMenu menus={this.articleMenus()}
                        selectedItemKey={this.state.selectedSection}
                        onSelect={(item: MenuItem) => {
                          this.setState({
                            selectedSection: item.key,
                            columns: new Columns.SizableColumns(this.getColumns(item.key)),
                          })
                        }}/>
            </div>
            {this.state.hasScheduledContentAccess && this.state.selectedSection === ArticleSections.scheduled &&
            <div className="scheduled-content-notification">
              To edit Scheduled Articles <b className="clickable-item"
              onClick={() => this.props.push(DashboardMenuOption.scheduledContent)}>
                Click here</b>
            </div>}
              <RasaDataGrid<DisplayArticle>
                  // Rasa Properties for entity, datasetName etc
                  entityName="communityArticle"
                  datasetName="communityUpcomingArticles"
                  datasetParams={[{
                      param: 'isActive',
                      value: this.state.selectedSection === ArticleSections.inactive ? 0 : 1,
                    }, {
                      param: 'cutOff',
                      value: this.getNextIssue() ? this.getNextIssue().cutoff : null,
                  }]}
                  editDefaultState={true}
                  forceReload={this.state.forceReload}
                  // events
                  onDataReceived={this.dataReceived}
                  normalizeDataOnChange={this.normalizeBoost}
                  onRowClick={(p: GridRowClickEvent) =>  this.editPost(p.dataItem)}
                  sortable={true}
                  pageable={true}
                  pageSize={50}
                  pagingSource={PagingSource.local}
                  gridFilter={this.filterData}
                  gridTransform={this.transformData}
                  data={[]}>
                {this.state.columns && this.state.columns.buildColumns(this.state.panelWidth, this.state.currentRole)}
              </RasaDataGrid>
          </div>}
      </div>
    )
  }

  private initialize = () => {
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.emailTemplate),
      this.sharedStore.getValue(SharedKeys.person),
      this.sharedStore.getValue(SharedKeys.role),
    ])
    .then(([activeCommunity, emailTemplate, person, role]) => {
      const initialNextIssue = activeCommunity.data.schedule_is_active ? activeCommunity.nextIssue : null
      const avlFeatures: BillingPlanDetailCode[] = activeCommunity.billingInfo.currentPlan.features || []
      // Need to invert the structure!
      this.setState({
        bulkAIImageGenerationCount: Constants.BULK_AI_IMAGE_GENERATION_COUNT,
        bulkAIDescriptionGenerationCount: Constants.BULK_AI_DESCRIPTION_GENERATION_COUNT,
        columns: new Columns.SizableColumns(this.getColumns()),
        communityId: activeCommunity.communityId,
        currentRole: activeCommunity.role,
        emailLayoutId: activeCommunity.communityInfo.data.email_layouts.filter((s: any) => s.is_active)[0].id,
        isScheduleActive: activeCommunity.communityInfo.data.schedule.filter((a) => a.is_active === 1).length > 0,
        emailTemplate: emailTemplate.toString(),
        hasScheduledContentAccess: avlFeatures.indexOf(BillingPlanDetailCode.SCHEDULED_CONTENT) > -1,
        hasReserveContentAccess: avlFeatures.indexOf(BillingPlanDetailCode.RESERVE_CONTENT) > -1
          || role === Roles.super_admin,
        hasSectionAccess: activeCommunity.billingInfo.usageStats.currentPlanMaxSections > 0,
        initialNextIssue,
        isSuperUser: role === Roles.super_admin,
        panelWidth: window.innerWidth - Columns.LEFT_PANEL_DEFAULT_WIDTH,
      })
      this.getArticlesCount(activeCommunity.communityId)
      this.getSections(activeCommunity.communityId)
      this.getSources(activeCommunity.communityId)
      this.getSegments(activeCommunity.communityId)

      if (initialNextIssue) {
        // only care if we have next issue
        const now = new Date()
        const custoffEnd = new Date(activeCommunity.nextIssue.production_cutoff)
        const sendTime = new Date(activeCommunity.nextIssue.date)

        if (isInGenerationWindow(initialNextIssue, now)) {
          // set article grid to disbaled
          this.setState({isUpcomingArticlesEnabled: false})
          // set an interval after which it would be enabled again
          this.setUpcomingArticleChangeStateInterval(differenceInSeconds(sendTime, now))
        } else {
          // set article grid to enabled or it may already be enabled
          this.setState({isUpcomingArticlesEnabled: true})
          // set an interval after which it would be disabled again
          this.setUpcomingArticleChangeStateInterval(differenceInSeconds(custoffEnd, now))
        }
      }
    }).then(() => this.setMinimumArticlesCount())
  }

  private setUpcomingArticleChangeStateInterval = (duration: number) => {
    if (false && duration > 0) {
      setTimeout(() => {
        this.initialize()
      }, duration * 1000)
    }
  }

  private setMinimumArticlesCount = () => {
    this.context.entityMetadata.getEntityObject(
            'email_layout', this.state.communityId, this.state.emailLayoutId)
            .then((emailEntityObject: any) => {
              const minimumArticlesCount = emailEntityObject._data.minimum_newsbrief_count || 4
              this.setState({
                minimumArticlesCount: Number(minimumArticlesCount),
              })
            })
  }

  private handleResize = () => {
    this.setState({
      panelWidth: window.innerWidth - Columns.LEFT_PANEL_DEFAULT_WIDTH,
    })
  }

  private ArticleEditCell = (props: EditCellProps) => {
    return <EditableDropdownCell {...props}
      onReserved={this.onReserved}
      canMarkReserved={this.state.hasReserveContentAccess} />
  }
  private ImageCell = (props: EditCellProps) => {
    return <ImageCell {...props}
                      emailTemplate={this.state.emailTemplate}
                      warningMessage={Constants.IMAGE_MAY_BE_TOO_SMALL_TOOLTIP}/>
  }

  private getNextIssue(): UpcomingIssue {
    return this.context.store.getState().schedule.data.nextIssue || this.state.initialNextIssue
  }

  private isScheduledArticle(article: Article): boolean {
    return isTruthy(article.scheduled_article_id) && new Date(article.end_date) > new Date()
  }

  private isActiveScheduledArticle(article: Article): boolean {
    return this.isScheduledArticle(article) && isTruthy(article.is_active)
  }

  private getArticleCount(section: ArticleSections) {
    if (this.state.selectedSection === section) {
      return this.state.articlesToDisplay.filter((x) => this.filterData(x)).length || 0
    } else {
      const sectionCount = this.state.articlesCounts.filter((x) => x.Type === section)
      const duplicateCount = this.state.articlesToDisplay.map((x) => {
        if (x.duplicates.length > 0
            && (
              (section === ArticleSections.active && x.is_active)
              || (section === ArticleSections.inactive && !x.is_active)
              || (section === ArticleSections.scheduled && x.scheduled_article_id && x.is_active)
            )) {
              return x.duplicates.length
            } else {
              return 0
            }}).reduce((sum, number) => (sum + number), 0)
      return sectionCount.length ? sectionCount[0].Count - duplicateCount : 0
    }
  }

  private articleMenus = (): MenuItem[] => {
    const menus = [
      {
        name: 'Active (' + this.getArticleCount(ArticleSections.active) + ')',
        key: ArticleSections.active,
        component: null,
      },
      {
        name: 'Inactive (' + this.getArticleCount(ArticleSections.inactive) + ')',
        key: ArticleSections.inactive,
        component: null,
      },
    ]
    if (this.state.hasScheduledContentAccess) {
      menus.push({
        name: 'Scheduled (' + this.getArticleCount(ArticleSections.scheduled) + ')',
        key: ArticleSections.scheduled,
        component: null,
      })
    }
    return menus
  }

  private setSearch = (s: string) => {
    this.setState({
      search: s,
    })
  }

  private normalizeBoost = (item: any, event: ChangeEvent) => {
    if (event.field === 'boost_level' &&
        event.value === SUPER_BOOSTED_BOOST_LEVEL &&
        item.boost_level === SUPER_BOOSTED_BOOST_LEVEL) {
      return {
        ...item,
        boost_level: NORMAL_BOOSTED_BOOST_LEVEL,
      }
    } else {
      return item
    }
  }

  private proceedBulkUpdate = () => {
    this.context.entityMetadata.getEntityObject('communityArticle').then((entity) => {
      const newActive = this.state.selectedSection === ArticleSections.active ? 0 : 1
      const updateIds = this.state.articlesToDisplay.filter((x) =>
        this.filterData(x)).map((x) => {
        return x.id
      })
      const updatedArticles = this.state.updatedArticles.map((x) => {
        if (updateIds.includes(x.id)) {
          return {
            ...x,
            is_active: newActive,
          }
        }
        return x
      })
      entity.setBulkIds(this.state.communityId, updateIds);
      entity.data.is_active = newActive
      entity.save()
        .then(() => {
          this.setState({
            activeToggleCount: newActive ? this.state.activeToggleCount + updateIds.length
              : this.state.activeToggleCount - updateIds.length,
            updatedArticles,
            })
          this.context.store.dispatch(Flash.showFlashMessage(
            this.state.selectedSection === ArticleSections.active ? Constants.ARTICLE_BULK_DEACTIVATION_SUCCESS
              : Constants.ARTICLE_BULK_ACTIVATION_SUCCESS,
          ))
        })
        .catch(() => {
          this.context.store.dispatch(Flash.showFlashError(Constants.ARTICLE_BULK_UPDATE_FAILURE))
        })
      })
  }

  private onBulUpdateArticles = () => {
    this.setState({ showBulkArticleUpdateModal: false}, () => {
      this.proceedBulkUpdate()
    })
  }

  private onBulkAIImageGenerationClick = () => {
    if (this.state.selectedFilterOption.key === Constants.FilterOption.NO_IMAGE) {
      // Bulk generate images for first 10 articles
      const articles = []
      this.getDisplayedArticles().slice(this.state.bulkAIImageGenerationOffset, this.state.bulkAIImageGenerationOffset + this.state.bulkAIImageGenerationCount).forEach((article) => {
        articles.push({
          id: article.id,
          title: article.title,
          description: article.description,
        })
      })
      const payload = {
        articles,
      }
      const url: string = `${AjaxWrapper.getServerUrl()}/ai/${this.state.communityId}/bulk-generate-article-image`;
      return AjaxWrapper.ajax(url, HttpMethod.POST, payload)
        .then(() => {
          this.setState({
            bulkAIImageGenerationOffset: this.state.bulkAIImageGenerationOffset + this.state.bulkAIImageGenerationCount,
          })
          this.context.store.dispatch(Flash.showFlashMessage(Constants.ARTICLE_BULK_AI_IMAGE_GENERATION_MESSAGE))
        })
        .catch((error) => {
          this.context.store.dispatch(Flash.showFlashMessage(Constants.ARTICLE_BULK_AI_IMAGE_GENERATION_FAILURE_MESSAGE))
        }).finally(() => {
          this.setState({
            showAIIMageGenerationModal: false,
          })
        })
    }
  }

  private onBulkAIDescriptionGenerationClick = () => {
    if (this.state.selectedFilterOption.key === Constants.FilterOption.NO_DESCRIPTION) {
      // Bulk generate images for first 10 articles
      const articles = []
      this.getDisplayedArticles().slice(this.state.bulkAIDescriptionGenerationOffset, this.state.bulkAIDescriptionGenerationOffset + this.state.bulkAIDescriptionGenerationCount).forEach((article) => {
        articles.push({
          id: article.id,
          title: article.title,
          url: article.url,
        })
      })
      const payload = {
        articles,
      }
      const url: string = `${AjaxWrapper.getServerUrl()}/ai/${this.state.communityId}/bulk-generate-article-description`;
      return AjaxWrapper.ajax(url, HttpMethod.POST, payload)
        .then(() => {
          this.setState({
            bulkAIDescriptionGenerationOffset: this.state.bulkAIDescriptionGenerationOffset + this.state.bulkAIDescriptionGenerationCount,
          })
          this.context.store.dispatch(Flash.showFlashMessage(Constants.ARTICLE_BULK_AI_DESCRIPTION_GENERATION_MESSAGE))
        })
        .catch((error) => {
          this.context.store.dispatch(Flash.showFlashMessage(Constants.ARTICLE_BULK_AI_DESCRIPTION_GENERATION_FAILURE_MESSAGE))
        }).finally(() => {
          this.setState({
            showAIDescriptionGenerationModal: false,
          })
        })
    }
  }

  private onModalClose = () => {
    this.setState({
      showBulkArticleUpdateModal: false,
    })
  }

  private onAIImageGenerationModalClose = () => {
    this.setState({
      showAIIMageGenerationModal: false,
    })
  }

  private onAIDescriptionGenerationModalClose = () => {
    this.setState({
      showAIDescriptionGenerationModal: false,
    })
  }

  private bulkArticleUpdate() {
    if (this.state.selectedSection === ArticleSections.active) {
      this.setState({
        showBulkArticleUpdateModal: true,
      })
    } else {
      this.proceedBulkUpdate()
    }
  }

  private getBulkArticleUpdateModal(): JSX.Element {
    return (
      <div>
    <Modal isOpen={this.state.showBulkArticleUpdateModal}>
          <ModalBody className="delete-modal">
            <div>
              <h4>Are You Sure?</h4>
              <p>{Constants.ARTICLES_BULK_ARCHIVE_WARNING}</p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.onBulUpdateArticles}>
              Okay
            </Button>
            <Button onClick={this.onModalClose}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }

  private showBulkAIImageGenerationModal = () => {
    this.setState({
      showAIIMageGenerationModal: true,
    })
  }

  private getBulkAIImageGenerationModal(): JSX.Element {
    return (
      <div>
    <Modal isOpen={this.state.showAIIMageGenerationModal}>
          <ModalBody className="delete-modal">
            <div>
              <h4>Are You Sure?</h4>
              <p>You want t-rex to generate images for first
                {this.getDisplayedArticles().length > this.state.bulkAIImageGenerationCount ? this.state.bulkAIImageGenerationCount :
                  this.getDisplayedArticles().length} articles?
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.onBulkAIImageGenerationClick}>
              Okay
            </Button>
            <Button onClick={this.onAIImageGenerationModalClose}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }

  private showBulkAIDescriptionGenerationModal = () => {
    this.setState({
      showAIDescriptionGenerationModal: true,
    })
  }

  private getBulkAIDescriptionGenerationModal(): JSX.Element {
    return (
      <div>
    <Modal isOpen={this.state.showAIDescriptionGenerationModal}>
          <ModalBody className="delete-modal">
            <div>
              <h4>Are You Sure?</h4>
              <p>You want t-rex to generate description for first
                {this.getDisplayedArticles().length > this.state.bulkAIDescriptionGenerationCount ? this.state.bulkAIDescriptionGenerationCount
                : this.getDisplayedArticles().length} articles?
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.onBulkAIDescriptionGenerationClick}>
              Okay
            </Button>
            <Button onClick={this.onAIDescriptionGenerationModalClose}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }

  private forceReload = () => {
    this.setState({forceReload: true}, () => this.setState({forceReload: false}))
  }

  private postEditArticle = (p: any) => {
    if (p.typeId) {
      const updatedArticles = this.state.updatedArticles.map((x) => {
        if (x.id === p.typeId) {
          const selectedSection = this.state.sections.find((section) => section.category_type_id === p.categoryTypeId)
          return {
            ...x,
            category_type: p.contentCategory,
            title: p.title,
            description: p.description,
            section_name: selectedSection ? selectedSection.name : null,
            custom_tags: p.customTags,
            hosted_image_url: p.hostedImage ? p.hostedImage.url : x.hosted_image_url,
            site_name: p.publisher,
            community_partner_code: p.community_partner_code,
          }
        }
        return x
      })
      this.setState({
        updatedArticles,
      })
    }
    this.forceReload()
  }

  private getbulkUpdateArticlesCount = () => {
    if (this.state.selectedSection === ArticleSections.active) {
      return this.state.articlesToDisplay.filter((x) => this.filterData(x)).length + this.state.activeToggleCount
    } else if (this.state.selectedSection === ArticleSections.inactive && this.state.activeToggleCount >= 0) {
      return this.state.articlesToDisplay.filter((x) => this.filterData(x)).length - this.state.activeToggleCount
    }
    return 0
  }

  private ClickableTitleCell = (props: any) => {
    return <TitleCell {...props} onClick={(e) => this.editPost(e)}/>
  }

  private ActiveCellTooltip = (props: any) => {
    const tooltipText = 'Use this toggle to remove an article that you don\'t want to be considered'
    return <TooltipCellHeader {...props} tooltip={tooltipText}/>
  }

  private BoostCellTooltip = (props: any) => {
    const tooltipText = 'Boost this article to ensure it makes it into every contact\'s newsletter.  Super-boost it to ensure it is in the featured spot.'
    return <TooltipCellHeader {...props} tooltip={tooltipText}/>
  }

  private dataReceived = (data) => {
    const nextIssue: UpcomingIssue = this.getNextIssue()
    if (data.data) {
      // Force throwing away any upcoming articles that are after our cutoff window.
      // We don't want them at all.
      const articlesToDisplay = this.getArticlesToDisplay(data.data, nextIssue)
      this.setState({
        activeArticlesCount: articlesToDisplay.filter((a) => !!a.is_active).length,
        articles: articlesToDisplay,
        articlesToDisplay,
        articleHash: keyBy(articlesToDisplay, 'id'),
        updatedArticles: articlesToDisplay,
      })
      if (articlesToDisplay.filter((r) => r.is_active).length >= 100) {
        this.context.store.dispatch(showAppCue(APP_CUE_TYPE.ARTICLES_100))
      }
    }
  }

  private getArticlesToDisplay = (allArticles: DisplayArticle[], nextIssue: UpcomingIssue): DisplayArticle[] => {
    return allArticles
      .reduce((displayArticles: DisplayArticle[], current: DisplayArticle) => {
        const existing = displayArticles.findIndex((a: DisplayArticle) => this.matches(a, current))
        if (existing === -1) {
          return displayArticles .concat([{...current,
            duplicates: [],
          }])
        } else {
          displayArticles[existing].duplicates.push(current)
          return displayArticles
        }
      }, [])
      .filter((article) => (isEmpty(nextIssue) || this.isActiveScheduledArticle(article) ||
      Date.parse(article.created) <= Date.parse(nextIssue.cutoff)))
  }

  private matches = (article: DisplayArticle, current: DisplayArticle): boolean => {
    return article.title === current.title &&
    article.description === current.description &&
    article.is_active === current.is_active &&
    article.source === current.source &&
    article.section_name === current.section_name &&
    article.url.split('#')[0] === current.url.split('#')[0]
  }

  private editPost = (p: any) => {
    this.props.openModal(AddEditArticleModal.key, this.normalizeItem(p))
  }

  private normalizeItem = (p: any) => {
    if (!p) {
      return {
        communityId: this.state.communityId,
      }
    }
    const normalizedItem = {
      ...p,
      typeId: p.id,
      communityId: this.state.communityId,
      publisher: p.site_name,
      image: p.hosted_image_url,
      contentCategory: p.category_type,
      customTags: p.custom_tags,
      nlpTags: p.nlp_tags,
      sourceTags: p.source_tags,
      forceUpdate: p.duplicates.length ? p.duplicates : []
    }
    if (p.hosted_image_url) {
      normalizedItem.hostedImage = {
        url: p.hosted_image_url,
        width: p.image_width,
        height: p.image_height,
      }
    }
    return normalizedItem
  }

  private handleClick(item: string) {
    this.props.push(item);
  }

  private isArticleMatchingSearch = (a: DisplayArticle): boolean => {
    if (this.state.selectedFilterOption.key === Constants.FilterOption.NO_DESCRIPTION && a.description) {
      return false
    }
    if (this.state.selectedFilterOption.key === Constants.FilterOption.NO_IMAGE
        && a.hosted_image_url) {
      return false
    } else if (this.state.selectedFilterOption.key === Constants.FilterOption.AI_GENERATED_IMAGE
        && isFalsey(tryParseJSON(a.options).ai_generated_image)) {
      return false
    } else if (this.state.selectedFilterOption.key === Constants.FilterOption.AI_GENERATED_DESCRIPTION
        && isFalsey(tryParseJSON(a.options).ai_generated_description)) {
      return false
    } else if (this.state.selectedFilterOption.key === Constants.FilterOption.IMAGE_WARNING && (!a.hosted_image_url
      || !imageIsTooSmall(a.image_height, a.image_width, this.state.emailTemplate))) {
      return false
    } else if (this.state.selectedFilterOption.key === Constants.FilterOption.TOGGLE_ON_OFF && !a.last_updated_community_person_id) {
      return false
    } else if (this.state.selectedSources.length > 0 && !this.state.selectedSources.find((x) => x.name === a.source_name
      || (a.source === SourceTypes.native && a.source === x.source_type))) {
      return false
    } else if (this.state.selectedSections.length > 0) {
      // note that selectedSections is an array
      // combination could be: 1 section or multiple sections
      // no section and one of the section or multiple sections
      const selectedSections = [...this.state.selectedSections]
      const noSectionIndex = selectedSections.indexOf(Constants.NO_SECTION)
      if (noSectionIndex > -1) {
        selectedSections[noSectionIndex] = null
      }
      if (!selectedSections.includes(a.section_name)) {
        return false
      }
    } else if (this.state.selectedSegments.length > 0) {
      // note that selectedSegments is an array
      // combination could be: 1 segment or multiple segments
      // no segment and one of the segment or multiple segments
      const selectedSegments = [...this.state.selectedSegments]
      const noSegementIndex = selectedSegments.indexOf(DEFAULT_COMMUNITY_PARTNER_CODES.NO_CODE)
      const allSegementIndex = selectedSegments.indexOf(DEFAULT_COMMUNITY_PARTNER_CODES.ALL)
      if (noSegementIndex > -1) {
        selectedSegments.push(null)
      }
      if (!selectedSegments.includes(a.community_partner_code) && allSegementIndex === -1) {
        return false
      }
    } else if (this.state.selectedFilterOption.key === Constants.FilterOption.AI_RECOMMEND_DEACTIVATION && (!a.blacklist_match ||
        a.blacklist_match !== Constants.AI_RECOMMENDATION_BLACK_LIST_TAG)) {
      return false
    }
    if (this.state.search.length >= 3) {
      const searchTerm = this.state.search.toLowerCase()
      if (
        (a.title || '').toLowerCase().includes(searchTerm) ||
        (a.description || '').toLowerCase().includes(searchTerm) ||
        (a.url || '').toLowerCase().includes(searchTerm) ||
        (a.source_name || '').toLowerCase().includes(searchTerm)) {
          return true
      } else {
        return false
      }
    } else {
      return true
    }
  }
  private articleIsNotDupe = (a: DisplayArticle) => {
    return this.state.articlesToDisplay.find((o) => o.id === a.id)
  }
  private transformData = (a: DisplayArticle): DisplayArticle => {
    return this.state.updatedArticles.filter((x) => x.id === a.id)[0]
  }
  private getDisplayedArticles = () => {
    return this.state.updatedArticles.filter((a) => this.filterData(a))
  }
  private filterData = (a: DisplayArticle): boolean => {
    const nextIssue: UpcomingIssue = this.getNextIssue()
    if (!this.isArticleMatchingSearch(a)) {
      return false
    }

    // We want to filter active based on the state when we loaded the page
    // so they don't toggle back and forth between the pages.
    // So - don't test a (the article we have that's *live* in the grid)
    // but instead test the corresponding article within our state - the articles that
    // were found when we loaded the page.
    const stateArticle = this.articleInState(a) || a

    if ((this.state.selectedSection === ArticleSections.active) &&
      (!isTruthy(stateArticle.is_active) || stateArticle.scheduled_article_id)) {
      return false
    } else if ((this.state.selectedSection === ArticleSections.inactive) &&
      (isTruthy(stateArticle.is_active) || stateArticle.scheduled_article_id)) {
      return false
    } else if ((this.state.selectedSection === ArticleSections.scheduled)
      && isFalsey(this.isActiveScheduledArticle(stateArticle))) {
      return false
    } else if (!this.articleIsNotDupe(a)) {
      return false
    }

    if (!isEmpty(nextIssue)) {
        // Only include boosted/lead posts or posts from before the cutoff
        return (
          (a.subscription_type === 'Lead') ||
          (nextIssue.cutoff && Date.parse(a.created) <= Date.parse(nextIssue.cutoff))
        )
    } else {
      return true
    }
  }

  private articleInState = (gridArticle: DisplayArticle): DisplayArticle => {
    return this.state.articleHash[gridArticle.id]
  }

  private onReserved = (props: EditCellProps, event) => {
    const newReserved = !props.dataItem.is_reserved
    props.onChange({
      dataItem: props.dataItem,
      syntheticEvent: event,
      field: 'is_reserved',
      value: newReserved,
    })
    this.editArticle(props.dataItem, {
      is_reserved: newReserved,
    })
  }

  private editArticle = (dataItem: any, change: any) => {
    const updatedArticles = this.state.updatedArticles.map((x) => {
      if (x.id === dataItem.id) {
        return {
          ...x,
          ...change,
        }
      } else {
        return x
      }
    })
    this.setState({
      updatedArticles,
    })
  }

  private toggleArticle = (dataItem: any, newIsActive: boolean) => {
    const change = {
      is_active:  (newIsActive ? 1 : 0),
    }
    this.editArticle(dataItem, change)
    if (this.state.activeArticlesCount === this.state.minimumArticlesCount && !newIsActive) {
      this.props.openModal(UpcomingArticlesModal.key, {
        minimumArticlesCount: this.state.minimumArticlesCount,
      })
    }
    const newActiveArticlesCount = newIsActive ? this.state.activeArticlesCount + 1 : this.state.activeArticlesCount - 1
    this.setState({
      activeToggleCount: newIsActive ? this.state.activeToggleCount + 1 : this.state.activeToggleCount - 1,
      activeArticlesCount: newActiveArticlesCount,
    })
  }

  private boostArticle = (dataItem: any, newBoostLevel: number) => {
    // can't use the generic editArticle method, as we aren't just changing
    // one article, we may be changing another article that is super boosted.
    const updatedArticles = this.state.updatedArticles.map((x) => {
      if (x.id === dataItem.id) {
        return {
          ...x,
          boost_level: newBoostLevel,
        }
      } else if (newBoostLevel === SUPER_BOOSTED_BOOST_LEVEL && x.boost_level === SUPER_BOOSTED_BOOST_LEVEL ) {
        return {
          ...x,
          boost_level: NORMAL_BOOSTED_BOOST_LEVEL,
        }
      } else {
        return x
      }
    })
    this.setState({
      updatedArticles,
    })
  }

  private ArticleActiveCell = (props: GridCellProps) => {
    return <ActiveCell {...props} onToggle={this.toggleArticle}/>
  }

  private ArticleBoostCell = (props: GridCellProps) => {
    return <BoostCell {...props} onBoost={this.boostArticle}/>
  }

  private getSections = (community) => {
    new Dataset()
    .loadCommunityDataset('sections', community)
    .then((response) => {
      const  avlSections = [...response[0], {category_type_id: 0, name: Constants.NO_SECTION}]
      return this.setState({sections: avlSections || []}, () => {
        this.setState({
          columns: new Columns.SizableColumns(this.getColumns()),
        })
      })
    })
  }

  private getArticlesCount = (community) => {
    const params = [
      {param: 'cutOff', value: this.getNextIssue() ? this.getNextIssue().cutoff : null},
    ]
    new Dataset()
    .loadCommunityDataset('upcomingArticlesCount', community, params)
    .then((response) => {
      this.setState({
        articlesCounts: response[0],
      })
    })
  }

  private getColumns = (section: any = ArticleSections.active): Columns.SizableColumn[] => {
    switch (section) {
      case ArticleSections.scheduled:
        return [
          {
            cell: this.ImageCell,
            field: '',
            minDisplayWidth: Columns.TABLET_DISPLAY_WIDTH,
            sortable: false,
            title: 'Image',
            width: 130,
          },
          {
            cell: TitleCell,
            field: 'title',
            minWidth: 300,
            title: 'Title',
          },
          {
            cell: SourceCell,
            field: 'site_name',
            title: 'Source',
            width: 150,
          },
          {
            cell: DateTimeCell,
            field: 'created',
            minDisplayWidth: Columns.TABLET_DISPLAY_WIDTH,
            title: 'Published',
            width: 150,
          },
          {
            cell: DateTimeCell,
            field: 'start_date',
            minDisplayWidth: Columns.TABLET_DISPLAY_WIDTH,
            title: 'Start Date',
            width: 150,
          },
          {
            cell: DateTimeCell,
            field: 'end_date',
            minDisplayWidth: Columns.TABLET_DISPLAY_WIDTH,
            title: 'End Date',
            width: 150,
          },
        ]
      default:
        return [
          {
            cell: this.ArticleActiveCell,
            field: 'is_active',
            headerCell: this.ActiveCellTooltip,
            title: 'Allow',
            width: 100,
          },
          {
            cell: this.ImageCell,
            field: '',
            minDisplayWidth: Columns.TABLET_DISPLAY_WIDTH,
            sortable: false,
            title: 'Image',
            width: 130,
          },
          {
            cell: this.ClickableTitleCell,
            field: 'title',
            minWidth: 300,
            title: 'Title',
          },
          {
            cell: ArticleLink,
            sortable: false,
            title: '',
            width: 35,
          },
          {
            cell: SourceCell,
            field: 'site_name',
            title: 'Source',
            width: 150,
          },
          {
            cell: this.ArticleBoostCell,
            field: 'boost_level',
            headerCell: this.BoostCellTooltip,
            title: 'Boost',
            width: 75,
          },
          {
            cell: DateTimeCell,
            field: 'created',
            minDisplayWidth: Columns.TABLET_DISPLAY_WIDTH,
            title: 'Published',
            width: 150,
          },
          {
            field: 'section_name',
            title: 'Section',
            width: 100,
            hidden: this.state.sections.length <= 1,
          },
          {
            cell: this.ArticleEditCell,
            field: '',
            title: '',
            width: 50,
          },
        ]
    }
  }
  private getSources = (communityId) => {
    return new Dataset()
    .loadCommunityDataset('communitySources', communityId)
    .then((response) => {
      if (!response) {
        return
      }
      const mergedSources = []
      response[0].map((source) => {
        if (source.source_type !== SourceTypes.native ||
          !mergedSources.find((x) => x.source_type === SourceTypes.native)) {
          mergedSources.push(source)
        }
      })
      this.setState({
        sources: response[0] || [],
        mergedSources,
      })
    })
  }

  private getSegments = (communityId) => {
    return new Dataset()
    .loadCommunityDataset('communityPartnerSegmentCode', communityId, [{param: 'includeDefaultOptions', value: 1}])
    .then((response) => {
      if (!response) {
        return
      }
      this.setState({
        segments: response[0] || [],
      })
    })
  }

  private onSourcesFilterChange(selections: any[]) {
    const sourcesTo = selections.map((s) => s.value)
    this.setState({
      selectedSources: sourcesTo.map((x) => {
        return this.state.mergedSources.find((s) => s.id === x)
      }),
    })
  }
  private onSegmentsFilterChange(selections: any[]) {
    const segmentsTo = selections.map((s) => s.value)
    this.setState({
      selectedSegments: segmentsTo,
    })
  }
  private onSectionsFilterChange(selections: any[]) {
    const sectionsTo = selections.map((s) => s.value)
    this.setState({
      selectedSections: sectionsTo,
    })
  }
}

export const ContentPoolUpcomingArticles = GenericRedux.registerNewComponentWithModals<DisplayArticle>(
  UpcomingArticlesComponent,
  'upcoming_articles',
  [UpcomingArticlesModal.key, AddEditArticleModal.key, GeneratedSummaryModalComponent.key, ImageGalleryModal.key],
  EmptyDisplayArticle,
  )
