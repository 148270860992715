import * as Flash from 'components/flash'
import { HeaderComponent } from 'components/header/component'
import { preventDefaultThen } from 'components/link'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import * as GenericRedux from 'generic/genericRedux'
import React, { Component } from 'react'
import { Button } from 'reactstrap'
import * as Redux from 'redux'
import { injectReducer } from 'store/index'

import './_styles.scss'
import * as Constants from './constants'
import * as Types from './types'
import { Grid, GridColumn } from '@progress/kendo-react-grid'
import { DateTimeCell } from '../../gridcells/datetime'
import { EditCellProps } from '../../../generic/editCell'
import { downloadTextFile, tryParseJson } from 'generic/utility'

class SourceHistoryComponent extends Component<Types.Props, Types.State> {
  public static contextType = RasaContext

  constructor(props: Types.Props) {
    super(props)
    this.state = Constants.InitialState
  }

  public componentDidMount = () => {
    this.context.user.init().then(({activeCommunity}) => {
      this.setState({
        error: '',
        isLoading: false,
        selectedCommunity: activeCommunity.communityId,
      })
    })
  }

  public render() {
    return <div className="source-check-wrapper">
      <HeaderComponent
        icon={'contentHistory'}
        title={'SOURCE History'}
        subTitle={'SOURCE HISTORY'}
        description={[
          'See why we are not able to find a feed.',
        ]}/>
      {this.state.isLoading ?
        <Loading size="64"/>
      :
        <div>
          <div className="input-area">
            <form onSubmit={preventDefaultThen(() => this.getSourceCheckResults())}>
              <input autoFocus disabled={this.state.isLoading} className="field-textbox"
                placeholder="Enter the URL to review"
                value={this.state.url}
                onChange={(e: any) => this.setState({ url: e.target.value })}
              />
              <span className="buttons">
                <Button disabled={this.state.url.length === 0} >Fetch</Button>
              </span>
            </form>
          </div>
          {this.state.error &&
             <span className="warning" dangerouslySetInnerHTML={{__html: this.state.error || 'We could not locate a Source for this URL'}} />
          }
          {this.state.data && this.state.data.length > 0 ?
            <Grid data={this.state.data} className="analytics-counts-grid" scrollable="none">
              <GridColumn field="created" title="Run date/time" cell={DateTimeCell}/>
              <GridColumn field="status" title="Modified-Since"/>
              <GridColumn field="number_of_posts" title="Number of pull articles"/>
              <GridColumn field="file" title="File" cell={this.FileDownloadCell}/>
            </Grid> : null
          }
        </div>
      }
    </div>
  }


  private FileDownloadCell = (props: EditCellProps) => {
    return <td>
      <div onClick={() => this.downloadSourceFile(props.dataItem.source_id, props.dataItem.id)}>
        Download File
      </div>
    </td>
  }

  private getSourceCheckResults = () => {
    this.setState({data: [], error: '', isLoading: true}, () =>
      AjaxWrapper
      .ajax(`${AjaxWrapper.getServerUrl()}/cs/${this.state.selectedCommunity}/source-history`, HttpMethod.POST, {
        url: this.state.url,
        limit: Constants.SOURCE_LIMIT
      })
      .then((response: Types.SourceRunResult) => {
        if (response.success) {
          this.setState({
            data: response.source_run,
          })
        } else {
          this.setState({
            error: response.message || 'Unknown Error',
          })
        }
        this.setState({
          isLoading: false,
        })
      }).catch((err) => {
        this.setState({
          error: 'An error occurred while fetching the results.',
          isLoading: false,
        })
      })
    )
  }

  private downloadSourceFile = (sourceId: number, sourceRunId: number) => {
    this.setState({error: '', isLoading: true})
    const url = `${AjaxWrapper.getServerUrl()}/cs/${this.state.selectedCommunity}/download-source-history/${sourceId}/${sourceRunId}`
    AjaxWrapper.ajax(url, HttpMethod.GET, null)
      .then((response: {content: string}) => {
        if (response.content) {
          let fileExtension = 'xml'
          if (Object.keys(tryParseJson(response.content)).length > 0) {
            fileExtension = 'json'
          }
          downloadTextFile(response.content, fileExtension, `${sourceRunId}.${fileExtension}`)
        } else {
          this.context.store.dispatch(Flash.showFlashError(Constants.FILE_NOT_FOUND_ERROR))
        }
        this.setState({
          isLoading: false,
        })
      }).catch((err) => {
      this.context.store.dispatch(Flash.showFlashError(Constants.FILE_NOT_FOUND_ERROR))
      this.setState({
        isLoading: false,
      })
    })
  }
}


export const SourceHistory = GenericRedux.createConnect(SourceHistoryComponent, Constants.REDUX_STORE_HOME)
injectReducer(
  Constants.REDUX_STORE_HOME,
  Redux.combineReducers({
    data: GenericRedux.createGenericReducer(Constants.REDUX_STORE_HOME, {}),
  }),
)
